@import "./../assets/global";

.Panel{
  background-color: white;
  color: black;
  min-height: 100vh;

  .error_box,
  .success_box{
    position: absolute;
    width: 100%;
    padding-right: 23px;
    text-align: right;

    @include md{
      margin-top: -12px;
    }
  }
  .error_box{
    color: #bb3c48;
  }

  .success_box{
    color: #6cbb66;
  }
}

.hidden-sm{
  @include md{
    display: none;
  }
}

.contentHolder2{
  max-width: 1200px;
  padding: 0 20px;
  margin: 100px auto auto;

  @include md{
    margin: auto;
  }

  & h1{
    font-size: 36px;
    margin-bottom: 50px;
  }
}

.form-control{
  border: 1px solid black;
}

.btn{
  float: right;

  @include md{
    max-width: 100%;
    display: block;
    float: unset;
    margin: auto;
    margin-top: 13px;
  }
}

.messageHolder{
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

.sentMessages{
  padding-left: 75px;

  @include md{
    margin-top: 40px;
    padding-left: calc(var(--bs-gutter-x) * .5);

    & h1{
      text-align: center;
    }
  }
}

.MessageItem:not(:first-of-type){
  margin-top: 50px;
}

.loadingHolder{
  text-align: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
}

