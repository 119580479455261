@import "./../../assets/global";

.LangPicker{
    font-weight: 500;
    font-family: 'TheSansB W7 Bold';
    font-size: 18px;

    & img{
        margin-left: 6px;
    }

    .langpicker_dropdown{
        display: none;
        z-index: 999;
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 15px 0;
        min-width: 200px;
        font-size: 16px;
        font-weight: normal;
        font-family: 'TheSansB W5 Plain';

        @include sm{
            min-width: unset;
        }

        & ul{
            list-style: none;
            margin: 0;
            padding: 0;

            & li{
                padding: 10px 20px;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.5);

                &.selected,
                &:hover{
                    color: rgba(235, 113, 37, 1);
                    font-weight: 500;
                    font-family: 'TheSansB W7 Bold';
                }
            }
        }
    }

    &:hover{
        .langpicker_dropdown{
            display: block;
        }
    }
}
