@font-face {
    font-family: 'TheSansB W4 SemiLight';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W4 SemiLight'), url('TheSansBW4SmLt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W4 SemiLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W4 SemiLight Italic'), url('TheSansBW4SmLtIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W2 ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W2 ExtraLight'), url('TheSansBW2XtraLt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W2 ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W2 ExtraLight Italic'), url('TheSansBW2XtraLtIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W3 Light';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W3 Light'), url('TheSansBW3Light.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W3 Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W3 Light Italic'), url('TheSansBW3LtIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W5 Plain';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W5 Plain'), url('TheSansBW5Plain.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W5 Plain Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W5 Plain Italic'), url('TheSansBW5PlainIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W6 SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W6 SemiBold'), url('TheSansBW6SmBd.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W6 SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W6 SemiBold Italic'), url('TheSansBW6SmBdIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W7 Bold';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W7 Bold'), url('TheSansBW7Bold.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W7 Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W7 Bold Italic'), url('TheSansBW7BoldIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W8 ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W8 ExtraBold'), url('TheSansBW8XtraBd.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W8 ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W8 ExtraBold Italic'), url('TheSansBW8XtraBdIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W9 Black';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W9 Black'), url('TheSansBW9Black.woff') format('woff');
}


@font-face {
    font-family: 'TheSansB W9 Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSansB W9 Black Italic'), url('TheSansBW9BlackIt.woff') format('woff');
}


@font-face {
    font-family: 'TheSans UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('TheSans UltraLight'), url('TheSansUltLt.woff') format('woff');
}


@font-face {
    font-family: 'TheSans UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TheSans UltraLight Italic'), url('TheSansUltLtIt.woff') format('woff');
}
