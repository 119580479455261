.MessageItem{
    font-weight: 16px;

    & img{
        height: 18px;
    }

    & h2{
        font-size: 24px;
        margin-bottom: 10px;
        color: #4c4c4c;
    }

    & .date{
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 2px;
    }

    & .message{
        margin-bottom: 5px;

        &.limit{
            max-height: 100px;
            overflow: hidden;
        }
    }

    & .more{
        font-weight: 500;
        color: #4c4c4c;
        margin-bottom: 0;
        cursor: pointer;

        &.active{
            & img{
                transform: rotate(180deg);
            }
        }
    }

    & .deletBtn{
        float: right;

        &:hover > img,
        &:focus > img{
            opacity: 0.7;
        }
    }
}
