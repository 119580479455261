@import "./../assets/global";

.Login{
  background-color: #002F5F;
  color: white;
  min-height: 100vh;
}

.contentHolder{
  max-width: 475px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0 20px;

  & h1{
    font-size: 36px;
    margin-bottom: 50px;
  }

  .error_box{
    position: absolute;
    color: #bb3c48;
    padding-left: 10px;
  }

  @include sm {
    position: relative;
    transform: translate(0, 0);
    top: 0;
    left: 0;
    margin: auto;

    & h1{
      margin-bottom: 40px;
    }

    & .error_box{
      margin-top: -12px;
    }
  }
}
