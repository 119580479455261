.Logout{
    & a{
        text-decoration: none;
        font-weight: 500;
        font-family: 'TheSansB W7 Bold';
        font-weight: 18px;
        color: rgba(139, 139, 139, 1);
        &:hover{
            text-decoration: underline;
        }
    }
}
