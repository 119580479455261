@import "./assets/fonts/style.css";
@import "./assets/global";

body {
  margin: 0;
  font-family: 'TheSansB W5 Plain';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b{
  font-family: 'TheSansB W7 Bold';
  font-weight: normal;
}

h1, h2{
  font-family: 'TheSansB W7 Bold';
  font-weight: 500;
}

label{
  font-family: 'TheSansB W6 SemiBold';
  font-weight: normal;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit .btn {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.form-control{
  height: 72px;
  font-size:16px;
  padding: 0 35px;
  border: none;
  border-radius: 10px;
}

textarea.form-control{
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 200px;
}

.btn-large{

  &:hover{
    background: #004a94;
  }

  &:focus{
    background: #005ab4;
  }

  margin-top: 40px;
  background: #002F5F;
  color: white;
  line-height: 31px;
  font-weight: 500;
  font-family: 'TheSansB W7 Bold';
  font-size: 24px;
  width: 100%;
  max-width: 435px;
  padding: 25px;
  position: relative;
  border: none;
  border-radius: 10px;

  &.btn-white{
    background: white;
    color: #002F5F;

    &:hover{
      background: rgb(245, 245, 245);
    }

    &:focus{
      background: rgb(241, 241, 241);
    }
  }

  & img{
    position: absolute;
    right: 25px;
    color: black;
    margin-top: 9px;
    height: 15px;
  }

  @include sm {
    margin-top: 15px;
  }
}

.btn-blue{
  background: #002F5F;

  &:hover{
    background: #004a94;
  }

  &:focus{
    background: #005ab4;
  }
}

.btn-nostyle{
  border: none;
  background: none;
  display: inline-block;
}
