@import "./../../assets/global";

.Header{
  padding: 80px 100px 40px 100px;

  @include sm{
    padding: 50px 20px;
  }
}

.logo{
  width: 200px;
}

.headerHolder{
  display: flex;
  align-items: center;

  & .LangPicker{
    margin-left: 100px;
    margin-right: 100px;

    @include md{
      margin: auto;
    }
  }

  & .Logout{
    margin-left: auto;
  }
}
